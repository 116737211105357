

import BaseLayout from "@/components/BaseLayout.vue";
import GroupCard from "@/components/card/GroupCard.vue";
import { IonFab, IonFabButton, IonIcon,IonRefresher,  IonRefresherContent, IonLoading} from "@ionic/vue";
import {add, refresh } from 'ionicons/icons';
import GroupServices from "@/services/group.services";


export default {
  name: 'FindGroup',
  components:{
    BaseLayout,
    GroupCard,
    IonFabButton,
    IonFab,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonLoading
  },
  setup(){
    return{
      add,
      refresh
    }
  },
  created(){
    this.loadGroups();
  },
  methods:{
    loadGroups(){
      this.loading= true;
      GroupServices.getAllGroups().then(
          res =>{
            this.loading=false;
            this.groups = res;
            // $event.target.complete();
          }, error => {
            console.log(error);
            this.error= true;
            this.loading = false;
          }
      )
    },
    refreshData($event){
      this.loadGroups();
      $event.target.complete();
    }
  },
  computed:{
    // groups(){
    //   return this.$store.getters['group/groups'];
    // }
  },
  data(){
    return{
      loading: false,
      groups: [],
      error: false
    }
  }
}

